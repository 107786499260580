<template>
  <div id="dashboard-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <IntroBanner v-if="!(projectSelection && projectSelection.length)" link="/project/create" headline="Willkommen zurück!">
      <p>
        Starte jetzt dein erstes Projekt und beginne deine Optimierungsreise.
      </p>
      <div>
        <router-link class="btn btn-primary btn-lg mt-4" to="/project/create">Jetzt Projekt erstellen</router-link>
      </div>
    </IntroBanner>

    <div v-else class="row">
      <div class="col-12">
        <div class="row align-items-stretch mb-3">
          <div class="col-lg-6">
            <h3>Deine Website</h3>
            <NetworkGraph v-if="nodes && nodes.length"
                          :nodes="nodes"
                          :links="links"
                          :reduced="true"
            ></NetworkGraph>
            <IntroBanner v-else headline="Jetzt scrapen" link="/scraper" icon="fa-cloud-download" btn-text="Zum Scraper"></IntroBanner>

            <router-link to="/links" class="btn btn-primary mt-4 mx-auto">
              Zur Website-Übersicht <i class="fal fa-arrow-right"></i>
            </router-link>
          </div>
          <div class="col-lg-6">
            <h3>Deine Performance</h3>
            <CalculationFunnel v-if="project.calculations && project.calculations.length"
                               :funnel-steps="project.calculations[0].funnelSteps"
                               currency="€"
                               :editable="true"
            ></CalculationFunnel>
            <IntroBanner v-else headline="Jetzt berechnen" link="/calculations" icon="fa-calculator" btn-text="Zum Performance-Rechner"></IntroBanner>

            <router-link to="/calculations" class="btn btn-primary mt-4 mx-auto">
              Zum Performance-Rechner <i class="fal fa-arrow-right"></i>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-12">
        <hr>
        <h3>Deine Erkenntnisse und Ideen</h3>
      </div>
      <div v-for="(prop, index) in props" class="col-md-6 mb-3" :key="'p' + index">
        <div class="card h-100">
          <div class="card-header">
            <div class="row">
              <div class="col">
                <h3 class="mb-0">
                  <i class="fad fa-fw" :class="prop.icon"></i>
                  {{ prop.title }}
                </h3>
              </div>
              <div class="col-auto h3 mb-0">
                <router-link :to="prop.route" target="_blank"><i class="fad fa-external-link"></i></router-link>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div v-if="typeof project[prop.prop] !== 'string' && project[prop.prop].length">
              <ul class="mb-0">
                <li v-for="(val, index) in project[prop.prop]" :key="'v' + index">
                  <router-link :to="prop.editRoute + val.id">
                    {{ val.title || "&lt;Unbenannt&gt;" }}
                  </router-link>
                </li>
              </ul>
            </div>
            <div v-else>
              <p class="text-muted">Noch keine Inhalte</p>
              <router-link :to="prop.route" class="btn btn-outline-primary">{{ prop.title }} erstellen <i class="fal fa-arrow-right"></i></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import NetworkGraph from "@/components/NetworkGraph";
import CalculationFunnel from "@/components/CalculationFunnel";
import IntroBanner from "@/components/IntroBanner";
export default {
  name: 'Dashboard',
  components: {
    IntroBanner,
    CalculationFunnel,
    NetworkGraph
  },
  data() {
    return {
      showExamples: false,
      propTemplates: [
        /*
        { route: '/links', editRoute: '/links/' },
        { route: '/contents', editRoute: '/contents/edit/' },
        */
        { route: '/canvas', editRoute: '/canvas/edit/' },
        { route: '/jobs', editRoute: '/jobs/edit/' },
        { route: '/personas', editRoute: '/personas/edit/' },
        { route: '/journeys', editRoute: '/journeys/edit/' },
        { route: '/audits', editRoute: '/audits/edit/' },
        { route: '/findings', editRoute: '/findings/edit/' },
        { route: '/ideas', editRoute: '/ideas/edit/' },
        { route: '/experiments', editRoute: '/experiments/edit/' },
      ],
    }
  },
  computed: {
    projectSelection() {
      return this.project.projectSelection;
    },
    props() {
      return this.propTemplates.map(p => {
        Object.assign(p, this.routes.reduce((acc, g) => acc.concat(g.links), []).find(l => l.route === p.route));
        return p;
      })
    },
    fullMap() {
      let nodes = this.project.netNodes.map(node => {
        let contentNode = this.project.contentNodes.find(n => n.id === node.id);
        let techNode = this.project.techNodes.find(n => n.id === node.id);
        node = {
          ...contentNode,
          ...techNode,
          ...node,
        };
        if (!node.title) {
          node.title = node.url.replace(this.rootUrl, "");
        }
        return node;
      });

      let externalNodes = JSON.parse(JSON.stringify(this.project.externalNodes));
      let contentNodes = [];
      contentNodes = contentNodes.concat(externalNodes.map(piece => {
        if (piece.platform) {
          piece._color = this.$store.state.platforms[piece.platform].color;
        }
        piece._cssClass = "depth-1";
        piece.depth = 1;
        piece.status = 200;

        for (let link of piece.links) {
          let conNode = externalNodes.find(n => n.url === link);
          if (!conNode) {
            contentNodes.push({
              id: this.generateId(),
              url: link,
              title: link,
              depth: 2,
              _cssClass: "depth-2",
              _color: "#cccccc"
            });
          }
        }
        return piece;
      }));

      nodes = nodes.concat(contentNodes);

      let links = this.project.netLinks;

      let contentLinks = [];
      externalNodes.forEach(piece => {
        let linkedNodes = [];
        if (piece.nodeUrls && piece.nodeUrls.length) {
          linkedNodes = piece.nodeUrls.map(url => {
            let conNode = nodes.find(n => n.url === url);
            if (!conNode) {
              conNode = {
                id: this.generateId(),
                url,
                title: url,
                depth: 2,
              }
              conNode._cssClass = "depth-1";
              nodes.push(conNode);
            }
            if (!contentNodes.find(n => n.url === url)) {
              contentNodes.push(conNode);
            }
            let link = {
              id: this.generateId(),
              sid: piece.id,
              tid: conNode.id,
            };
            if (piece.platform) {
              link._resetColor = this.$store.state.platforms[piece.platform].color;
            }
            return link;
          });
        }
        if (piece.links && piece.links.length) {
          for (let link of piece.links) {
            let conNode = externalNodes.find(n => n.url === link);
            if (conNode) {
              link = {
                id: this.generateId(),
                sid: piece.id,
                tid: conNode.id
              };
            } else {
              link = {
                id: this.generateId(),
                sid: piece.id,
                tid: contentNodes.find(p => p.title === link)
              };
            }
            if (piece.platform) {
              link._resetColor = this.$store.state.platforms[piece.platform].color;
            }
            linkedNodes.push(link);
          }
        }
        contentLinks = contentLinks.concat(linkedNodes);
      });
      links = links.concat(contentLinks);

      return {nodes, links, contentNodes, contentLinks};
    },
    nodes() {
      return this.fullMap.nodes;
    },
    links() {
      return this.fullMap.links;
    },
    templateTheories() {
      return this.$store.state.templateTheories.filter(template => !this.project.theories.find(theory => theory.template === template.title));
    },
    theories() {
      return this.project.theories.map(theory => {
        theory.audits = this.project.audits.filter(audit => audit.theoryId === theory.id);
        theory.findings = this.project.findings.filter(finding => finding.theoryId === theory.id);
        theory.ideas = this.project.ideas.filter(idea => idea.theoryId === theory.id);
        theory.experiments = this.project.experiments.filter(experiment => experiment.theoryId === theory.id);
        return theory;
      });
    },
  },
  methods: {
    addTheory(theory) {
      let temp = JSON.parse(JSON.stringify(theory));
      temp.id = this.generateId();

      this.project.theories.push(temp);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'theories', data: this.project.theories})
          .then(() => {
            this.$router.push("/theories/" + temp.id);
          });
    }
  }
}
</script>
